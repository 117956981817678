<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 mt-2">
          <VueSignaturePad
            id="signature"
            width="100%"
            height="500px"
            ref="signaturePad"
            :options="options"
          />
        </div>
      </div>
      <div class="row justify-content-end">
        
        <div class="col-3 mt-2">
          <button class="btn btn-outline-secondary mx-1" @click="undo">Undo</button>
          <button class="btn btn-outline-primary mx-1" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


// import module from "@/core/modules/CrudModule.js";
export default {

  props: {
    detail:Object,
    attachment:Array
  },

  data(){
    
    return{
      options: {
        penColor: "#000000"
      },
      // attachment:[],
      formData: new FormData(),
    }
  },

  methods: {

    // draw
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    // save() {
    //   console.log("test");
    // },
    async save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // this.attachment = data
       this.formData.append("attachment[]", this.dataURItoBlob(data));
      if(isEmpty == false){
          let attachments
          
          if(this.attachment.length > 0)
          {
            attachments = this.attachment
          }else{
            attachments = this.attachment.join()
          }

          this.formData.append("_method", "PUT");
          this.formData.append("attachment_list", attachments);
          for (const [key, value] of Object.entries(this.detail)) {
            this.formData.append(key, value);
          }
          // Make Request
          let response = await module.submit(
            this.formData,
            "medical-records/" + this.detail.id
          );
          // Check Response
          if (response.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              response.error.errors
            );
          } else {
            // Success
            Swal.fire(response.success.title, response.success.message, "success");
             this.$bvModal.hide("modal-drawing");
            
          }
    
      }
      else{
        Swal.fire("Gagal Menyimpan Gambar", "Area Gambar Masih Kosong" , "error");
      }
      
    },

    async getMedicalRecord() {
      let response = await module.get("medical-records/" + this.detail.id);

      if (response) {
        let attach = response.attachment.split(",");
        this.attachment = attach;
      }
    },

    // convert base64 to image
    dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {
      type: mimeString
    });
  }

  },
  mounted(){
    // this.getMedicalRecord()
  }


}
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.modal-dialog.modal-sm{
  max-width: 1450px;
}
</style>